import React from 'react';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <div className="bg-white text-gray-800 min-h-screen">
      {/* Header */}
      <header className="container mx-auto px-4 flex justify-between items-center py-6">
        <Link to="/" className="text-2xl font-bold">Process Ore AI</Link>
        <nav>
          <Link to="/" className="mx-2 hover:text-blue-600">Home</Link>
          <Link to="/features" className="mx-2 hover:text-blue-600">Features</Link>
          <Link to="/contact" className="mx-2 hover:text-blue-600">Contact</Link>
          <button onClick={() => {/* Handle pre-order action */}} className="mx-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition duration-300">Pre-order</button>
        </nav>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>

        <p className="mb-6">
          Jankauskas commercium LLP ("us", "we", or "our") operates the website https://processore.app (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of personal information we receive from users of the Site.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Information Collection and Use</h2>
        <p className="mb-6">
          While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name and email address ("Personal Information").
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Log Data</h2>
        <p className="mb-6">
          Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, and other statistics.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Cookies</h2>
        <p className="mb-6">
          Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Security</h2>
        <p className="mb-6">
          The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to This Privacy Policy</h2>
        <p className="mb-6">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
        <p className="mb-4">If you have any questions about this Privacy Policy, please contact us:</p>
        <address className="mb-4">
          Jankauskas commercium LLP<br />
          Company code: 304975440<br />
          Girulių g. 10-201, LT-12112 Vilnius<br />
          Lithuania<br />
          Phone: +370 644 62 927<br />
          Email: <a href="mailto:info@processore.app" className="text-blue-600 hover:underline">info@processore.app</a>
        </address>
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-200 py-12">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="text-2xl font-bold">Process Ore AI</div>
          <div className="flex space-x-4">
            <Link to="/privacy" className="hover:text-blue-600">Privacy</Link>
            <Link to="/terms" className="hover:text-blue-600">Terms</Link>
            <Link to="/contact" className="hover:text-blue-600">Contact</Link>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} Process Ore AI. All rights reserved.</p>
          <p>Process Ore AI: Mapping the Future of Business Processes</p>
        </div>
      </footer>
    </div>
  );
}

export default Privacy;