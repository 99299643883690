import React from 'react';
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div className="bg-white text-gray-800 min-h-screen"> 
      {/* Header */}
      <header className="container mx-auto px-4 flex justify-between items-center py-6">
        <Link to="/" className="text-2xl font-bold">Process Ore AI</Link>
        <nav>
          <Link to="/" className="mx-2 hover:text-blue-600">Home</Link>
          <Link to="/features" className="mx-2 hover:text-blue-600">Features</Link>
          <Link to="/contact" className="mx-2 hover:text-blue-600">Contact</Link>
          <button onClick={() => {/* Handle pre-order action */}} className="mx-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition duration-300">Pre-order</button>
        </nav>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Acceptance of Terms</h2>
        <p className="mb-6">
          By accessing or using the website at https://processore.app (the "Site"), you agree to be bound by these Terms of Service ("Terms").
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Use of the Site</h2>
        <p className="mb-6">
          You may use the Site for lawful purposes only and in accordance with these Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Intellectual Property</h2>
        <p className="mb-6">
          The Site and its original content, features, and functionality are owned by Jankauskas commercium LLP and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Termination</h2>
        <p className="mb-6">
          We may terminate or suspend access to our Site immediately, without prior notice or liability, for any reason whatsoever.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Changes to Terms</h2>
        <p className="mb-6">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Contact Us</h2>
        <p className="mb-4">If you have any questions about these Terms, please contact us:</p>
        <address className="mb-4">
          Jankauskas commercium LLP<br />
          Company code: 304975440<br />
          Girulių g. 10-201, LT-12112 Vilnius<br />
          Lithuania<br />
          Phone: +370 644 62 927<br />
          Email: <a href="mailto:info@processore.app" className="text-blue-600 hover:underline">info@processore.app</a>
        </address>
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-200 py-12">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="text-2xl font-bold">Process Ore AI</div>
          <div className="flex space-x-4">
            <Link to="/privacy" className="hover:text-blue-600">Privacy</Link>
            <Link to="/terms" className="hover:text-blue-600">Terms</Link>
            <Link to="/contact" className="hover:text-blue-600">Contact</Link>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} Process Ore AI. All rights reserved.</p>
          <p>Process Ore AI: Mapping the Future of Business Processes</p>
        </div>
      </footer>
    </div>
  );
}

export default Terms;