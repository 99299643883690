import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div className="bg-white text-gray-800 min-h-screen">
      {/* Header */}
      <header className="container mx-auto px-4 flex justify-between items-center py-6">
        <Link to="/" className="text-2xl font-bold">Process Ore AI</Link>
        <nav>
          <Link to="/" className="mx-2 hover:text-blue-600">Home</Link>
          <Link to="/#features" className="mx-2 hover:text-blue-600">Features</Link>
          <Link to="/contact" className="mx-2 hover:text-blue-600">Contact</Link>
          <button onClick={() => {/* Handle pre-order action */}} className="mx-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition duration-300">Pre-order</button>
        </nav>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Contact Us</h1>
        <p className="mb-6">We're here to help! Whether you have questions about our product, need support, or want to provide feedback, feel free to reach out to us using the information below.</p>

        {/* Contact Information */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Contact Information</h2>
          <address className="mb-4 not-italic">
            <strong>Jankauskas commercium LLP</strong><br />
            Company code: 304975440<br />
            Address: Girulių g. 10-201, LT-12112 Vilnius<br />
            Lithuania<br />
            <br />
            Phone: <a href="tel:+37064462927" className="text-blue-600 hover:underline">+370 644 62 927</a><br />
            Email: <a href="mailto:my@processore.app" className="text-blue-600 hover:underline">my@processore.app</a>
          </address>
        </div>

        {/* Contact Form section removed */}
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-200 py-12">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="text-2xl font-bold">Process Ore AI</div>
          <div className="flex space-x-4">
            <Link to="/privacy" className="hover:text-blue-600">Privacy</Link>
            <Link to="/terms" className="hover:text-blue-600">Terms</Link>
            <Link to="/contact" className="hover:text-blue-600">Contact</Link>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} Process Ore AI. All rights reserved.</p>
          <p>Process Ore AI: Mapping the Future of Business Processes</p>
        </div>
      </footer>
    </div>
  );
}

export default Contact;